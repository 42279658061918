@font-face {
  font-family: "GothamPro";
  src: url("assets/fonts/GothamPro/GothamProRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  letter-spacing: "0.1em";
}

body {
  margin: 0;
  font-family: "GothamPro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "GothamPro", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
